<template>
  <v-container fluid>
    <v-toolbar
      color="transparent"
      :dark="this.$store.state.darkTheme ? true : false"
    >
      <v-toolbar-title>
        <h1
          v-if="!isMobile()"
          class="font-weight-light"
        >
          Bibliography
        </h1>
        <h2
          v-if="isMobile()"
          class="font-weight-light"
        >
          Bibliography
        </h2>
      </v-toolbar-title>
      <v-btn
        v-if="!isMobile()"
        color="gred"
        absolute
        right
        href="full.bib"
      >
        Full export
        &thinsp;
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Espace recherche -->
    <v-row class="mx-1">
      <v-col>
        <v-row align="center">
          <v-col>
            <v-row>
              <v-select
                v-model="poleValue"
                :dark="this.$store.state.darkTheme?true:false"
                :items="this.$store.state.listAllPoles"
                label="Pole"
                class="mx-4"
                clearable
                :disabled="(!(yearValue=='' || yearValue ==null))||(!(templateValue=='' || templateValue ==null))"
                @keyup.enter="loadBib"
              />
              <!-- :disabled="!(poleValue=='' || poleValue ==null)" -->
              <v-text-field
                v-model="yearValue"
                :dark="this.$store.state.darkTheme?true:false"
                label="Year"
                class="mx-4"
                :rules="yearValue?[regex.year]:[]"
                clearable
                :disabled="(!(poleValue=='' || poleValue ==null))||(!(templateValue=='' || templateValue ==null))"
                @keyup.enter="loadBib"
              />
              <v-text-field
                v-model="templateValue"
                label="Project (pr-*)"
                :dark="this.$store.state.darkTheme?true:false"
                class="mx-4"
                :rules="templateValue?[regex.projectname]:[]"
                clearable
                :disabled="(!(poleValue=='' || poleValue ==null))||(!(yearValue=='' || yearValue ==null))"
                @keyup.enter="loadBib"
              />
            </v-row>
          </v-col>
          <v-col
            v-if="!isMobile()"
            cols="1"
            class="text-right"
            align-self="end"
          >
            <v-btn
              class="my-6"
              color="primary"
              fab
              @click.native="loadBib"
            >
              <v-icon large>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider :dark="this.$store.state.darkTheme ? true : false" />
        <v-row v-if="isMobile()">
          <v-col>
            <v-btn
              block
              color="primary"
              :dark="this.$store.state.darkTheme?true:false"
              @click.native="loadBib"
            >
              Search &thinsp;
              <v-icon large>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              color="gred"
              :dark="this.$store.state.darkTheme?true:false"
              block
              href="https://perseus.ujf-grenoble.fr/biblio/ciment/full.bib"
              target="_blank"
            >
              Full export
              &thinsp;
              <v-icon>mdi-export</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Espace resultat -->
    <v-row
      v-if="!empty"
      class="my-6 mx-6"
    >
      <div>
        <v-list
          v-for="(entry,k) in bib_entries"
          :key="k"
          color="transparent"
          :dark="$store.state.darkTheme?true:false"
          class="my-4"
        >
          <span class="title">
            {{ entry.title }}
          </span>
          <v-card-text>
            {{
              bib_entries_str[k]
            }}
          </v-card-text>
        </v-list>
      </div>
    </v-row>

    <v-row
      v-if="empty"
      class="my-6 mx-6 justify-center align-middle"
    >
      <v-alert
        type="error"
        text
      >
        No paper was found
      </v-alert>
    </v-row>
    <br>
    <!-- Definition des boites de dialogue de la page -->
    <!-- Boite de dialogue : action en cours -->
    <v-dialog
      v-model="updated"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // import store from '../store'
  import axios from 'axios'
  // import { parseBibFile } from 'bibtex'
  var parse = require('bibtex-parser')
  const Cite = require('citation-js')

  export default {
    data: () => ({
      bib_entries: [],
      bib_entries_str: [],
      empty: false,
      updated: false,

      // Initialisation des v-models
      poleValue: '',
      yearValue: '',
      templateValue: '',
      yearList: [],
      authorValue: '',

      regex: {
        year: v => (v || '').match(/^\d{4}$/) || 'Please enter a valid year',
        projectname: v => (v || '').match(/^pr-.*$/) || 'Project must begin by "pr-"',
      },

    }),
    computed: {
      styleDark () {
        return {
          'background-color': 'white',
          color: 'black',
        }
      },
      styleLight () {
        return {
          'background-color': '#031f30',
          color: 'white',
        }
      },
    },
    created () {
      var self = this
      if (this.bib_entries.length !== 0) {
        axios.get('/getall-bib4user/' + this.$store.state.account.login)
          .then(response => {
            if (response.data !== null) {
              self.bib_entries = parse.entries(atob(response.data))
            }
          })
      }
    },
    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },

      loadBib () {
        if (this.poleValue) {
          this.updated = true
          axios.get('/getall-bibByPole/' + this.poleValue)
            .then(response => {
              if (response.data.length !== 0) {
                this.empty = false
              
                this.bib_entries = []
                var list = response.data
                this.bib_entries = []
                this.bib_entries_str = []
                list.forEach(bib => {
                  var result = this.b64DecodeUnicode(bib)
                  var local = Cite.input(result)
                  local.forEach(entry => {
                    this.bib_entries.push(entry)
                  })
                  var separetedCiment = result.split('@')
                  for (let i = 1; i < separetedCiment.length; i++) {
                    var strCiment = '@' + separetedCiment[i]
                    // les blocs de biblio etant ensemble je les separe avec le @ pour les reformer en array
                    this.bib_entries_str.push(strCiment)
                  }
                  this.updated = false
                })
              } else {
                this.empty = true
                this.updated = false
              }
            })
        }
        if (this.yearValue) {
          this.updated = true
          if (this.templateValue) {
            axios.get('/getall-bibByYear/' + this.yearValue + '/template/'+ this.templateValue)
              .then(response => {
                if (response.data.length !== 0) {
                  this.empty = false
                  this.bib_entries = []
                  this.bib_entries_str = []
                  var list = response.data
                  this.bib_entries = []
                  var result = this.b64DecodeUnicode(list)
                  var local = Cite.input(result)
                  local.forEach(entry => {
                    this.bib_entries.push(entry)
                    var separetedCiment = result.split('@')
                    for (let i = 1; i < separetedCiment.length; i++) {
                      var strCiment = '@' + separetedCiment[i]
                      // les blocs de biblio etant ensemble je les separe avec le @ pour les reformer en array
                      this.bib_entries_str.push(strCiment)
                    }
                    this.updated = false
                  })
                } else {
                  this.empty = true
                }
              })
          }
          else {
            axios.get('/getall-bibByYear/' + this.yearValue + '/template/pr-*')
              .then(response => {
                if (response.data.length !== 0) {
                  this.empty = false
                  this.bib_entries = []
                  var list = response.data
                  this.bib_entries = []
                  this.bib_entries_str = []
                  var result = this.b64DecodeUnicode(list)
                  var local = Cite.input(result)
                  local.forEach(entry => {
                    this.bib_entries.push(entry)
                    var separetedCiment = result.split('@')
                    for (let i = 1; i < separetedCiment.length; i++) {
                      var strCiment = '@' + separetedCiment[i]
                      // les blocs de biblio etant ensemble je les separe avec le @ pour les reformer en array
                      this.bib_entries_str.push(strCiment)
                    }
                    this.updated = false
                  })
                } else {
                  this.empty = true
                }
              })
          }
        }
      },
      b64DecodeUnicode (str) {
        return decodeURIComponent(atob(str).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
      },
    },
  }
</script>

<style lang="scss">
  .btn-filter {
    min-width: 500px;
  }
</style>
